import { useParams } from "react-router-dom";
import { LeadCrudApi } from "../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../components/common/crud/CrudTable";
import { useContext, useEffect, useRef, useState } from "react";
import { ConstantContext } from "../context";
import {
  getNoteCPColumns,
  getNoteCPFormItems,
} from "../components/crud/NoteCPComponent";
import { Button, Col, Row, Space, Spin, Typography } from "antd";
import { getCrudApi } from "../client/CrudApi";
import { colors } from "../theme";
import StatusChangeModal, {
  IStatusChangeModalRefMethods,
} from "../components/modals/StatusChangeModal";
import { getConstantValue, getFormattedDate } from "../helpers/UtilHelper";

const { Text } = Typography;

const LeadDetailPage = () => {
  const crudTableRef = useRef<ICrudTableRefMethods>(null);
  const statusChangeModalRef = useRef<IStatusChangeModalRefMethods>(null);
  const { id } = useParams();
  const constants = useContext(ConstantContext);
  const [leadLoading, setLeadLoading] = useState(false);
  const [lead, setLead] = useState<any>();

  useEffect(() => {
    if (!id) return;
    refreshLead(id);
  }, [id]);

  const refreshLead = (id: string) => {
    setLeadLoading(true);
    LeadCrudApi.get(parseInt(id))
      .then((response) => setLead(response))
      .finally(() => setLeadLoading(false));
  };

  return (
    <>
      <Row
        style={{
          backgroundColor: colors.white,
          borderRadius: 4,
          marginBottom: 8,
          padding: 16,
        }}
      >
        {leadLoading ? (
          <Spin size="small" />
        ) : (
          lead && (
            <Col span={24}>
              <Space>
                <Text strong>{lead.fullName}</Text>
                <Text strong>{lead.email}</Text>
                <Text strong>{lead.phone}</Text>
                <Text strong>
                  {getConstantValue(lead.status, constants?.leadStatus)}
                </Text>
                <Text strong>{getFormattedDate(lead.createdAt)}</Text>
                <Text strong>
                  {lead.lastNoteDate
                    ? getFormattedDate(lead.lastNoteDate)
                    : "-"}
                </Text>
                <Text strong>{lead.timeRemainingToDay}</Text>
                <Text strong>
                  {lead.source
                    ? getConstantValue(lead.source, constants?.leadSources)
                    : "-"}
                </Text>
                <Text strong>
                  {lead.areaOfInterest
                    ? getConstantValue(lead.areaOfInterest, constants?.leadAois)
                    : "-"}
                </Text>
              </Space>
              <Row justify="end">
                <Button
                  type="primary"
                  onClick={() => {
                    statusChangeModalRef.current?.form.setFieldsValue({
                      id: id,
                      "@id": "/api/leads/" + id,
                    });
                    statusChangeModalRef.current?.setOpen(true);
                  }}
                >
                  Durum Değiştir
                </Button>
              </Row>
            </Col>
          )
        )}
      </Row>
      <CrudTable
        ref={crudTableRef}
        hideSearchBar
        entityLabel="Not"
        api={getCrudApi("leads/" + id + "/notes")}
        columns={getNoteCPColumns(constants)}
        addFormItems={getNoteCPFormItems(constants)}
        editFormItems={getNoteCPFormItems(constants, true)}
        expandable={{
          expandedRowRender: (row) => (
            <div style={{ maxWidth: "80vw" }}>
              <Text strong>İçerik: </Text> <Text>{row.content}</Text>
            </div>
          ),
        }}
      />
      <StatusChangeModal
        ref={statusChangeModalRef}
        afterAction={() => {
          if (id) refreshLead(id);
          crudTableRef.current?.refreshData();
        }}
      />
    </>
  );
};

export default LeadDetailPage;
