import { Form, Input, Select } from "antd";
import { email, max, min, required } from "../../helpers/FormRuleHelper";
import {
  getConstantValue,
  getFormattedDate,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { UserCrudApi } from "../../client/EntityApi";
import InputPhone from "../common/InputPhone";

export const getLeadCPColumns = (constants?: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Temsilci",
      dataIndex: "agent",
      key: "agent",
      render: (value: any) => value?.fullName ?? "-",
    },
    { title: "Ad Soyad", dataIndex: "fullName", key: "fullName" },
    {
      title: "E-Posta",
      dataIndex: "email",
      key: "email",
      render: (value: any) => value ?? "-",
    },
    { title: "Telefon", dataIndex: "phone", key: "phone" },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
      filters: constants?.leadStatus,
      render: (value: any) => getConstantValue(value, constants?.leadStatus),
    },
    {
      title: "Kayıt Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: any) => getFormattedDate(value),
    },
    {
      title: "Son Görüşme Tarihi",
      dataIndex: "lastNoteDate",
      key: "lastNoteDate",
      sorter: true,
      render: (value: any) => (value ? getFormattedDate(value) : "-"),
    },
    {
      title: "Aramaya Kalan Süre",
      dataIndex: "timeRemainingToCall",
      key: "timeRemainingToCall",
      sorter: true,
      render: (_: any, row: any) => row.timeRemainingToDay ?? "Aranmayacak",
    },
    {
      title: "Kaynak",
      dataIndex: "source",
      key: "source",
      filters: constants?.leadSources,
      render: (value: any) =>
        value ? getConstantValue(value, constants?.leadSources) : "-",
    },
    {
      title: "İlgi Alanları",
      dataIndex: "areaOfInterest",
      key: "areaOfInterest",
      filters: constants?.leadAois,
      render: (value: any) =>
        value ? getConstantValue(value, constants?.leadAois) : "-",
    },
  ];
};

export const getLeadCPFormItems = (constants?: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="@id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      {!isEdit && (
        <Form.Item
          label="Durum"
          name="status"
          rules={[required]}
          initialValue="newRegister"
        >
          <Select
            allowClear
            options={constants?.leadStatus}
            placeholder="Durum seçiniz"
          />
        </Form.Item>
      )}
      <Form.Item
        label="Ad Soyad"
        name="fullName"
        rules={[required, min(4), max(60)]}
      >
        <Input placeholder="Ad soyad giriniz" />
      </Form.Item>
      <Form.Item label="Telefon" name="phone" rules={[required]}>
        <InputPhone />
      </Form.Item>
      <Form.Item label="E-Posta" name="email" rules={[email]}>
        <Input placeholder="E-Posta giriniz" />
      </Form.Item>
      <Form.Item label="Kaynak" name="source">
        <Select
          allowClear
          options={constants?.leadSources}
          placeholder="Kaynak seçiniz"
        />
      </Form.Item>
      <Form.Item label="İlgi Alanı" name="areaOfInterest">
        <Select
          allowClear
          options={constants?.leadAois}
          placeholder="İlgi Alanı seçiniz"
        />
      </Form.Item>
      <Form.Item label="Temsilci (Aramak için yazın)" name="agent">
        <SearchSelect
          searchAction={(searchValue, setOptions, setLoading) =>
            UserCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(
                  getOptionsFromEntities(response["hydra:member"], "fullName")
                );
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
    </>
  );
};
