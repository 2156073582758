import { Form, Input, Select, Space, Tooltip, Typography } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { getConstantValue, getFormattedDate } from "../../helpers/UtilHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Text } = Typography;

export const getNoteCPColumns = (constants?: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Tür",
      dataIndex: "type",
      key: "type",
      filters: constants?.noteTypes,
      render: (value: any) =>
        value ? getConstantValue(value, constants?.noteTypes) : "Sistem Notu",
    },
    {
      title: "Notu Yazan Kişi",
      dataIndex: "author",
      key: "author",
      render: (value: any) => (value ? value.fullName : "Sistem"),
    },
    {
      title: (
        <Space>
          <Text>Durum</Text>
          <Tooltip title="Not oluşturulurkenki durum">
            <QuestionCircleOutlined style={{ cursor: "pointer" }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: "status",
      key: "status",
      render: (value: any) => getConstantValue(value, constants?.leadStatus),
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: any) => getFormattedDate(value),
    },
  ];
};

export const getNoteCPFormItems = (contants?: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="@id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Not Türü" name="type" rules={[required]}>
        <Select
          allowClear
          options={contants?.noteTypes}
          placeholder="Not türü seçiniz"
        />
      </Form.Item>
      <Form.Item
        label="İçerik"
        name="content"
        rules={[required, min(4), max(2000)]}
      >
        <TextArea rows={4} placeholder="İçerik giriniz." />
      </Form.Item>
    </>
  );
};
