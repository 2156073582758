import { useContext } from "react";
import { UserCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getAgentCPColumns,
  getAgentCPFormItems,
} from "../../components/crud/AgentCPComponent";
import { ConstantContext } from "../../context";

const AgentCrudPage = () => {
  const contants = useContext(ConstantContext);

  return (
    <CrudTable
      entityLabel="Temsilci"
      api={UserCrudApi}
      columns={getAgentCPColumns(contants)}
      addFormItems={getAgentCPFormItems()}
      editFormItems={getAgentCPFormItems(true)}
      setEditFields={(row) => {
        if (!row.team) return row;
        return {
          ...row,
          team: { label: row.team.name, value: row.team["@id"] },
        };
      }}
      beforeEditOperation={(values: any) => {
        let team = null;
        if (values.team) {
          team =
            typeof values.team === "object" ? values.team.value : values.team;
        }

        return { ...values, team: team };
      }}
    />
  );
};

export default AgentCrudPage;
