import Cookies from "js-cookie";

export const isLoggedIn = () => Boolean(Cookies.get("accessToken"));

export const saveCredentials = (accessToken: string, refreshToken: string) => {
  Cookies.set("accessToken", accessToken, { expires: 365 });
  Cookies.set("refreshToken", refreshToken, { expires: 365 });
  window.location.reload();
};

export const removeCredentials = () => {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  window.location.replace(window.location.origin);
};
