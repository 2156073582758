import { Form, Input, Select, Tag } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import {
  getFormattedDate,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { TeamCrudApi } from "../../client/EntityApi";

export const getAgentCPColumns = (constants?: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Ad Soyad", dataIndex: "fullName", key: "fullName" },
    { title: "Kullanıcı Adı", dataIndex: "username", key: "username" },
    {
      title: "Ekip/Ekip Lideri",
      dataIndex: "team",
      key: "team",
      render: (value: any) =>
        (value?.name ?? "-") + " / " + (value?.managerName ?? "-"),
    },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
    {
      title: "Kayıt Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => getFormattedDate(value),
    },
  ];
};

export const getAgentCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="@id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label="Ad"
        name="firstname"
        rules={[required, min(2), max(30)]}
      >
        <Input placeholder="Ad giriniz" />
      </Form.Item>
      <Form.Item
        label="Soyad"
        name="lastname"
        rules={[required, min(2), max(30)]}
      >
        <Input placeholder="Soyad giriniz" />
      </Form.Item>
      <Form.Item
        label="Kullanıcı Adı"
        name="username"
        rules={[required, min(4), max(30)]}
      >
        <Input placeholder="Kullanıcı adı giriniz" />
      </Form.Item>
      {isEdit ? (
        <Form.Item
          name="plainPassword"
          label="Şifre"
          rules={[min(4), max(255)]}
        >
          <Input.Password placeholder="Şifre değiştirmek istemiyorsanız boş bırakın." />
        </Form.Item>
      ) : (
        <Form.Item
          name="plainPassword"
          label="Şifre"
          rules={[required, min(4), max(255)]}
        >
          <Input.Password placeholder="Şifre giriniz." />
        </Form.Item>
      )}
      <Form.Item label="Ekip" name="team">
        <SearchSelect
          searchAction={(searchValue, setOptions, setLoading) =>
            TeamCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <Form.Item
        name="isActive"
        label="Aktif mi?"
        rules={[required]}
        initialValue={true}
      >
        <Select
          options={[
            { label: "Evet", value: true },
            { label: "Hayır", value: false },
          ]}
        />
      </Form.Item>
    </>
  );
};
