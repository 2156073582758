import { useEffect, useState } from "react";
import { ConstantContext, UserContext } from "../../context";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Col, Image, Layout, Row } from "antd";
import { loginPath } from "../../router";
import ProfileMenu from "./ProfileMenu";
import GeneralApi from "../../client/GeneralApi";
import AuthApi from "../../client/AuthApi";
import { isLoggedIn } from "../../helpers/AuthHelper";
import { colors } from "../../theme";

const { Content } = Layout;

const BasicLayout = () => {
  const navigate = useNavigate();

  const [constants, setConstants] = useState<any>();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    if (!isLoggedIn()) return;
    GeneralApi.getConstants().then((response) => setConstants(response));
    AuthApi.currentUser().then((response) => setUser(response));
  }, []);

  return !isLoggedIn() ? (
    <Navigate to={loginPath} />
  ) : (
    <ConstantContext.Provider value={constants}>
      <UserContext.Provider value={{ user, setUser }}>
        <Layout style={{ minHeight: "100vh", background: colors.background }}>
          <Row style={{ height: "100%" }} justify="center">
            <Col span={20}>
              <Row
                style={{ height: "100px" }}
                justify="space-between"
                align="middle"
              >
                <Image
                  style={{ borderRadius: 8, cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  preview={false}
                  src="/assets/techoffice.gif"
                  width={70}
                  height={70}
                />
                <ProfileMenu />
              </Row>
              <Content style={{ paddingTop: 16 }}>
                <Outlet />
              </Content>
            </Col>
          </Row>
        </Layout>
      </UserContext.Provider>
    </ConstantContext.Provider>
  );
};

export default BasicLayout;
