import { Card, Col, Row } from "antd";
import { Navigate, Outlet } from "react-router-dom";
import { successPath } from "../../router";
import { colors } from "../../theme";
import { isLoggedIn } from "../../helpers/AuthHelper";

const AuthLayout = () => {
  return isLoggedIn() ? (
    <Navigate to={successPath} />
  ) : (
    <Row
      justify="center"
      align="middle"
      style={{ height: "100vh", background: colors.background }}
    >
      <Card style={{ width: "100%", maxWidth: 400 }}>
        <Row justify="center" align="middle">
          <Col span={24}>
            <Outlet />
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default AuthLayout;
