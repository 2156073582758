import { createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NotFoundPage from "./pages/common/NotFoundPage";
import BasicLayout from "./components/layouts/BasicLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import LoginPage from "./pages/common/LoginPage";
import LeadCrudPage from "./pages/crud/LeadCrudPage";
import AgentCrudPage from "./pages/crud/AgentCrudPage";
import TeamCrudPage from "./pages/crud/TeamCrudPage";
import LeadDetailPage from "./pages/LeadDetailPage";

export const loginPath = "/giris-yap";
export const successPath = "/";

export const menus = [
  {
    title: "Müşteri Adayları",
    to: "musteri-adaylari",
    src: "../assets/menu/musteri-adaylari.jpg",
  },
  {
    title: "Temsilciler",
    to: "temsilciler",
    src: "../assets/menu/temsilciler.jpg",
  },
  {
    title: "Ekipler",
    to: "ekipler",
    src: "../assets/menu/ekipler.jpg",
  },
];

const getPrivateRoutes = () => [
  { path: "/", element: <Dashboard /> },
  { path: "/musteri-adaylari", element: <LeadCrudPage /> },
  { path: "/musteri-aday-detay/:id", element: <LeadDetailPage /> },
  { path: "/temsilciler", element: <AgentCrudPage /> },
  { path: "/ekipler", element: <TeamCrudPage /> },
];

const getAuthRoutes = () => [{ path: "/giris-yap", element: <LoginPage /> }];

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: getAuthRoutes(),
  },
  {
    element: <BasicLayout />,
    children: getPrivateRoutes(),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
