import { Form, Input, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CrudTableTitleRow from "./CrudTableTitleRow";
import { ExpandableConfig, SorterResult } from "antd/es/table/interface";
import CrudTableProcessButton from "./CrudTableProcessButton";
import {
  ReloadOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CrudTableAddModal from "./CrudTableAddModal";
import CrudTableEditModal from "./CrudTableEditModal";
import CrudTableDeleteModal from "./CrudTableDeleteModal";
import { FormInstance } from "antd/lib/form/Form";
import { colors } from "../../../theme";

interface ICrudTableProps {
  api: any;
  entityLabel?: string;
  columns: ColumnsType<any>;
  fixed?: "left" | "right";
  getAll?: (
    page?: number,
    pageSize?: number,
    search?: string,
    orders?: string[],
    filters?: string[]
  ) => any;
  extraTitleProcess?: () => ReactNode;
  hideRefreshProcess?: (() => boolean) | boolean;
  hideAddProcess?: (() => boolean) | boolean;
  post?: (values: any) => any;
  expandable?: ExpandableConfig<any>;
  afterRefreshOperation?: (data: any[]) => void;
  addFormItems?: ReactNode;
  editFormItems?: ReactNode;
  modalWidths?: string | number;
  setEditFields?: (row: any) => any;
  beforeAddOperation?: (values: any) => any;
  afterAddOperation?: () => void;
  beforeEditOperation?: (values: any) => any;
  afterEditOperation?: () => void;
  addModalOkText?: string;
  editModalOkText?: string;
  deleteModalOkText?: string;
  addModalCancelText?: string;
  editModalCancelText?: string;
  deleteModalCancelText?: string;
  addModalMaskClosable?: boolean;
  editModalMaskClosable?: boolean;
  deleteModalMaskClosable?: boolean;
  deleteModalMessage?: string;
  extraRowProcess?: (row: any) => ReactNode;
  customTableTitle?: () => ReactNode;
  customTableMiddleCol?: () => ReactNode;
  hideTableTitle?: (() => boolean) | boolean;
  hideSearchBar?: (() => boolean) | boolean;
  hideDefaultTitleProcess?: (() => boolean) | boolean;
  hideDefaultRowProceses?: ((row: any) => boolean) | boolean;
  hideEditProcess?: ((row: any) => boolean) | boolean;
  hideDeleteProcess?: ((row: any) => boolean) | boolean;
  extendedAddFormOnCancel?: () => void;
  extendedEditFormOnCancel?: () => void;
  extendedDeleteFormOnCancel?: () => void;
}

const { Text } = Typography;

export interface ICrudTableRefMethods {
  refreshData: () => void;
  addFormInstance: FormInstance;
  editFormInstance: FormInstance;
}

const CrudTable = forwardRef((props: ICrudTableProps, ref) => {
  const [addFormInstance] = Form.useForm();
  const [editFormInstance] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>([]);

  const [selectedId, setSelectedId] = useState<number>();
  const [addDialogIsOpen, setAddDialogIsOpen] = useState<boolean>(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    refreshData: refreshData,
    addFormInstance: addFormInstance,
    editFormInstance: editFormInstance,
  }));

  const refreshData = (
    page?: number,
    pageSize?: number,
    search?: string,
    orders?: string[],
    filters?: string[]
  ) => {
    setLoading(true);
    const getAll = props.getAll ?? props.api.getAll;
    getAll(page, pageSize, search, orders, filters)
      .then((response: any) => {
        setData(response["hydra:member"]);
        setTotal(response["hydra:totalItems"]);
        if (props.afterRefreshOperation)
          props.afterRefreshOperation(response["hydra:member"]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(
    () => refreshData(page, pageSize, search, orders, filters),
    // eslint-disable-next-line
    [page, pageSize, search, orders, filters]
  );

  const createOrderQuery = (sorter: SorterResult<any>) => {
    const order = sorter.order === "ascend" ? "asc" : "desc";
    return `order[${sorter.columnKey}]=${order}`;
  };

  const checkVisibility = (
    condition?: ((row?: any) => boolean) | boolean,
    conditionParameters?: any
  ) => {
    if (condition) {
      if (typeof condition === "boolean") {
        return !condition;
      }

      return !(conditionParameters
        ? condition(conditionParameters)
        : condition());
    }

    return true;
  };

  return (
    <>
      <div
        style={{
          border: "1px solid white",
          borderRadius: "4px 4px 0px 0px",
          backgroundColor: colors.white,
        }}
      >
        <Table
          rowKey="id"
          loading={loading}
          dataSource={data}
          expandable={props.expandable}
          scroll={{ x: "max-content" }}
          columns={[
            ...props.columns,
            {
              fixed: props.fixed,
              title: "",
              render: (row: any) => {
                return (
                  <>
                    {props.extraRowProcess ? props.extraRowProcess(row) : <></>}
                    {checkVisibility(props.hideDefaultRowProceses, row) && (
                      <>
                        {checkVisibility(props.hideEditProcess, row) && (
                          <CrudTableProcessButton
                            tooltipText={props.entityLabel + " Düzenle"}
                            icon={<EditOutlined />}
                            onClick={() => {
                              props.setEditFields
                                ? editFormInstance.setFieldsValue({
                                    ...row,
                                    ...props.setEditFields(row),
                                  })
                                : editFormInstance.setFieldsValue(row);
                              setSelectedId(row.id);
                              setEditDialogIsOpen(true);
                            }}
                          />
                        )}
                        {checkVisibility(props.hideDeleteProcess, row) && (
                          <CrudTableProcessButton
                            tooltipText={props.entityLabel + " Sil"}
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              setSelectedId(row.id);
                              setDeleteDialogIsOpen(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                );
              },
            },
          ]}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(pagination, filters, sorter) => {
            setPage(pagination.current ?? 1);
            setPageSize(pagination.pageSize ?? 10);
            let mappedOrders = [];
            if (Array.isArray(sorter)) {
              mappedOrders = sorter.map((sorter) => createOrderQuery(sorter));
            } else {
              mappedOrders = sorter.order ? [createOrderQuery(sorter)] : [];
            }

            let mappedFilters = Object.keys(filters)
              .filter((item) => filters[item] !== null)
              .map((key) => key + "=[" + filters[key] + "]");

            setFilters(mappedFilters);
            setOrders(mappedOrders);
          }}
          title={() => (
            <CrudTableTitleRow
              firstCol={
                props.customTableTitle
                  ? props.customTableTitle()
                  : checkVisibility(props.hideTableTitle) && (
                      <Text style={{ fontWeight: 600 }}>
                        {props.entityLabel
                          ? props.entityLabel + " Listesi"
                          : ""}
                      </Text>
                    )
              }
              secondCol={
                props.customTableMiddleCol
                  ? props.customTableMiddleCol()
                  : checkVisibility(props.hideSearchBar) && (
                      <Input.Search
                        allowClear
                        enterButton
                        size="large"
                        placeholder={props.entityLabel + " Ara"}
                        onSearch={(value) => {
                          setPage(1);
                          setPageSize(10);
                          setSearch(value);
                        }}
                      />
                    )
              }
              thirdCol={
                <>
                  {props.extraTitleProcess ? props.extraTitleProcess() : <></>}
                  {!props.hideDefaultTitleProcess && (
                    <>
                      {checkVisibility(props.hideRefreshProcess) && (
                        <CrudTableProcessButton
                          tooltipText={"Yeniden Yükle"}
                          icon={<ReloadOutlined />}
                          onClick={() =>
                            refreshData(page, pageSize, search, orders)
                          }
                        />
                      )}
                      {checkVisibility(props.hideAddProcess) && (
                        <CrudTableProcessButton
                          tooltipText={props.entityLabel + " Ekle"}
                          icon={<PlusOutlined />}
                          onClick={() => setAddDialogIsOpen(true)}
                        />
                      )}
                    </>
                  )}
                </>
              }
            />
          )}
        />
      </div>
      <CrudTableAddModal
        width={props.modalWidths}
        maskClosable={props.addModalMaskClosable}
        okText={props.addModalOkText}
        cancelText={props.addModalCancelText}
        isOpen={addDialogIsOpen}
        setIsOpen={setAddDialogIsOpen}
        refreshData={refreshData}
        endpoint={props.post ? props.post : props.api.create}
        entityLabel={props.entityLabel}
        formInstance={addFormInstance}
        formItems={props.addFormItems}
        beforeOperation={props.beforeAddOperation}
        afterOperation={props.afterAddOperation}
        extendedOnCancel={props.extendedAddFormOnCancel}
      />
      <CrudTableEditModal
        width={props.modalWidths}
        maskClosable={props.editModalMaskClosable}
        okText={props.editModalOkText}
        cancelText={props.editModalCancelText}
        isOpen={editDialogIsOpen}
        setIsOpen={setEditDialogIsOpen}
        refreshData={refreshData}
        endpoint={props.api.edit}
        entityLabel={props.entityLabel}
        formInstance={editFormInstance}
        formItems={props.editFormItems}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        beforeOperation={props.beforeEditOperation}
        afterOperation={props.afterEditOperation}
        extendedOnCancel={props.extendedEditFormOnCancel}
      />
      <CrudTableDeleteModal
        maskClosable={props.deleteModalMaskClosable}
        okText={props.deleteModalOkText}
        cancelText={props.deleteModalCancelText}
        isOpen={deleteDialogIsOpen}
        setIsOpen={setDeleteDialogIsOpen}
        refreshData={refreshData}
        endpoint={props.api.delete}
        entityLabel={props.entityLabel}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        deleteModalMessage={props.deleteModalMessage}
        extendedOnCancel={props.extendedDeleteFormOnCancel}
      />
    </>
  );
});

export default CrudTable;
