import dayjs from "dayjs";

export const getConstantValue = (value: any, constants?: any[] | undefined) => {
  const findedConstant = constants?.find((constant) => {
    return constant.value === value;
  });

  return findedConstant ? findedConstant.label : "";
};

export const getRemoteFileUrl = (uid: string) =>
  process.env.REACT_APP_UPLOAD_URL + uid;

export const getOptionsFromEntities = (
  entityArray: any,
  labelName = "name",
  valueName = "@id"
) => {
  return entityArray.map((entity: any) => {
    return {
      id: entity["id"],
      label: entity[labelName],
      value: entity[valueName],
    };
  });
};

export const getFormattedDate = (value: string, format?: string) => {
  return dayjs(value).format(format ?? "DD.MM.YYYY");
};
