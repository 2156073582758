import { useContext, useRef } from "react";
import { LeadCrudApi } from "../../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/common/crud/CrudTable";
import {
  getLeadCPColumns,
  getLeadCPFormItems,
} from "../../components/crud/LeadCPComponent";
import { ConstantContext } from "../../context";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { ExpandOutlined, SwapOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import StatusChangeModal, {
  IStatusChangeModalRefMethods,
} from "../../components/modals/StatusChangeModal";

const LeadCrudPage = () => {
  const crudTableRef = useRef<ICrudTableRefMethods>(null);
  const statusChangeModalRef = useRef<IStatusChangeModalRefMethods>(null);
  const contants = useContext(ConstantContext);
  const navigate = useNavigate();

  return (
    <>
      <CrudTable
        ref={crudTableRef}
        fixed="right"
        entityLabel="Müşteri Adayı"
        api={LeadCrudApi}
        columns={getLeadCPColumns(contants)}
        addFormItems={getLeadCPFormItems(contants)}
        editFormItems={getLeadCPFormItems(contants, true)}
        extraRowProcess={(row: any) => (
          <>
            <CrudTableProcessButton
              tooltipText="Müşteri Adayı Detay"
              icon={<ExpandOutlined />}
              onClick={() => navigate("/musteri-aday-detay/" + row.id)}
            />
            <CrudTableProcessButton
              tooltipText="Durum Değiştir"
              icon={<SwapOutlined />}
              onClick={() => {
                statusChangeModalRef.current?.form.setFieldsValue({
                  id: row.id,
                  "@id": row["@id"],
                });
                statusChangeModalRef.current?.setOpen(true);
              }}
            />
          </>
        )}
        setEditFields={(row) => {
          if (!row.agent) return row;
          return {
            ...row,
            agent: { label: row.agent.fullName, value: row.agent["@id"] },
          };
        }}
        beforeEditOperation={(values: any) => {
          let agent = null;
          if (values.agent) {
            agent =
              typeof values.agent === "object"
                ? values.agent.value
                : values.agent;
          }

          return { ...values, agent: agent };
        }}
      />
      <StatusChangeModal
        ref={statusChangeModalRef}
        afterAction={() => crudTableRef.current?.refreshData()}
      />
    </>
  );
};

export default LeadCrudPage;
