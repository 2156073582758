import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";

const ProfileUpdateForm = () => {
  return (
    <>
      <Form.Item
        label="Ad"
        name="firstname"
        rules={[required, min(2), max(30)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Soyad"
        name="lastname"
        rules={[required, min(2), max(30)]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default ProfileUpdateForm;
