import { forwardRef, useImperativeHandle, useState } from "react";
import { Form, FormInstance, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import StatusChangeForm from "../forms/StatusChangeForm";
import { LeadCrudApi } from "../../client/EntityApi";

export interface IStatusChangeModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

export interface IStatusChangeModalProps {
  afterAction?: () => void;
}

const StatusChangeModal = forwardRef((props: IStatusChangeModalProps, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Durum Değiştir"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          LeadCrudApi.edit(values.id, values)
            .then(() => {
              message.success("Durum değiştirme başarılı, not oluşturuldu.");
              props.afterAction && props.afterAction();
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Değiştir"
    >
      <Form form={form} layout="vertical">
        <StatusChangeForm />
      </Form>
    </Modal>
  );
});

export default StatusChangeModal;
