import { Col, Row, Image } from "antd";
import { colors } from "../theme";
import { useNavigate } from "react-router-dom";
import { menus } from "../router";

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <Row gutter={[16, 32]}>
      {menus.map((menu, index) => (
        <Col key={index}>
          <div className="menu-card" onClick={() => navigate(menu.to)}>
            <Row justify="center">
              <Image
                style={{ borderRadius: 8 }}
                src={menu.src}
                preview={false}
                width={200}
                height={200}
              />
            </Row>
            <Row
              justify="center"
              style={{ fontSize: 18, color: colors.white, marginTop: 8 }}
            >
              {menu.title}
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Dashboard;
