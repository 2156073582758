import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const LoginForm = () => {
  return (
    <>
      <Form.Item
        name="username"
        label="Kullanıcı Adı"
        rules={[required, min(4), max(255)]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="Kullanıcı adınızı giriniz."
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Şifre"
        rules={[required, min(4), max(255)]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Şifrenizi giriniz."
        />
      </Form.Item>
    </>
  );
};

export default LoginForm;
